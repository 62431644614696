var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "booking_split_detail popup_wrap",
      staticStyle: { width: "900px", height: "530px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("msg.CSBK100.256"))),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "content_box mt10" },
          [
            _c("div", { staticClass: "flex_box" }, [
              _c("h2", { staticClass: "content_title" }, [
                _vm._v(_vm._s(_vm.$t("msg.CSBK100.257"))),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "bkbl_no tbl_topright" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.258"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "txt_bar2" }),
                _c("span", [_vm._v(_vm._s(_vm.bkgNo))]),
              ]),
            ]),
            _c("table", { staticClass: "tbl_row" }, [
              _vm._m(0),
              _c("tbody", [
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.259")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("ul", { staticClass: "schedule_info" }, [
                      _c("li", [
                        _vm._v(_vm._s(_vm.objBookingDetailMain.porPlcEnm)),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.CSBK100.260")) +
                            " : " +
                            _vm._s(_vm.etd) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.030")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("ul", { staticClass: "schedule_info" }, [
                      _c("li", [
                        _vm._v(_vm._s(_vm.objBookingDetailMain.dlyPlcEnm)),
                      ]),
                      _c("li", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("msg.CSBK100.261")) +
                            " : " +
                            _vm._s(_vm.eta) +
                            " "
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.262")))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.objBookingDetailMain
                            ? _vm.objBookingDetailMain.vslNm +
                                "/" +
                                _vm.objBookingDetailMain.voyNo
                            : ""
                        ) +
                        " "
                    ),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.011")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      attrs: {
                        type: "text",
                        id: "txtRealCompany",
                        name: "txtRealCompany",
                        maxlength: "30",
                        disabled: "",
                      },
                      domProps: {
                        value: _vm.objBookingDetailMain.actShprCstEnm,
                      },
                    }),
                    _vm.lang == "KOR"
                      ? _c("span", { staticClass: "float_right" }, [
                          _c("input", {
                            attrs: {
                              type: "checkbox",
                              id: "consYn",
                              name: "consYn",
                              disabled: "",
                            },
                            domProps: {
                              checked: _vm.objBookingDetailMain.consYn == "Y",
                            },
                          }),
                          _c("label", { attrs: { for: "consYn" } }, [
                            _c("span"),
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.263"))),
                          ]),
                        ])
                      : _vm._e(),
                  ]),
                ]),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.030-1")))]),
                  _vm._v(" "),
                  _c("td", [
                    _vm.cstCatCd != "Y"
                      ? _c("div", { staticClass: "tbl_form" }, [
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.cboTerm,
                                    expression: "objBookingDetailMain.cboTerm",
                                  },
                                ],
                                attrs: {
                                  name: "cboTerm",
                                  id: "cboTerm",
                                  disabled: "",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.fnChangeType($event, "cboTerm")
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "cboTerm",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("CFS"),
                                ]),
                                _c("option", { attrs: { value: "03" } }, [
                                  _vm._v("CY&CFS"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.podShtmCd,
                                    expression:
                                      "objBookingDetailMain.podShtmCd",
                                  },
                                ],
                                attrs: {
                                  name: "podTerm",
                                  id: "podTerm",
                                  disabled: "",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "podShtmCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("CFS"),
                                ]),
                              ]
                            ),
                          ]),
                        ])
                      : _c("div", { staticClass: "tbl_form" }, [
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.cboTerm,
                                    expression: "objBookingDetailMain.cboTerm",
                                  },
                                ],
                                attrs: { name: "cboTerm", id: "cboTerm" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "cboTerm",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "02" } }, [
                                  _vm._v("Tackle"),
                                ]),
                                _c("option", { attrs: { value: "03" } }, [
                                  _vm._v("FI"),
                                ]),
                              ]
                            ),
                          ]),
                          _c("span", { staticClass: "dv" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.objBookingDetailMain.podShtmCd,
                                    expression:
                                      "objBookingDetailMain.podShtmCd",
                                  },
                                ],
                                attrs: { name: "podTerm", id: "podTerm" },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.objBookingDetailMain,
                                      "podShtmCd",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c("option", { attrs: { value: "01" } }, [
                                  _vm._v("CY"),
                                ]),
                                _c("option", { attrs: { value: "05" } }, [
                                  _vm._v("Tackle"),
                                ]),
                                _c("option", { attrs: { value: "07" } }, [
                                  _vm._v("FO"),
                                ]),
                                _c("option", { attrs: { value: "09" } }, [
                                  _vm._v("T/S Zone"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.027-1")))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.bookingDate))]),
                ]),
                _vm.lang == "JPN" && _vm.fwdrCstCd == "01"
                  ? _c("tr", [
                      _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.264")))]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { attrs: { colspan: "3" } },
                        [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.objBookingDetailMain.bkgShprCstNo,
                                  expression:
                                    "objBookingDetailMain.bkgShprCstNo",
                                },
                              ],
                              attrs: { name: "bkgShprCstNo" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.objBookingDetailMain,
                                    "bkgShprCstNo",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }, [
                                _vm._v(_vm._s(_vm.$t("msg.CSBK100.050"))),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.bkgShprList, function (item, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: {
                                      selected:
                                        item.bkgShprCstNo ==
                                        _vm.objBookingDetailMain.bkgShprCstNo,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(item.bkgShprCstEnm) + " "
                                    ),
                                  ]
                                )
                              }),
                            ],
                            2
                          ),
                          _vm._v("     "),
                          _c("input", {
                            staticClass: "input txt",
                            staticStyle: { width: "220px" },
                            attrs: {
                              type: "text",
                              name: "bkgShprCstEnm",
                              maxlength: "50",
                            },
                            domProps: {
                              value: _vm.objBookingDetailMain.bkgShprCstEnm,
                            },
                            on: {
                              blur: function ($event) {
                                this.value = this.value.toUpperCase()
                              },
                            },
                          }),
                          _c("br"),
                          _c("font", { attrs: { color: "red" } }, [
                            _vm._v(
                              "* 弊社と運賃のご契約をされた方を意味します。"
                            ),
                          ]),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm.lang == "JPN"
                  ? _c("tr", [
                      _c("th", [_vm._v("通関業者")]),
                      _c("td", { attrs: { colspan: "3" } }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.objBookingDetailMain.fwdrCstNo,
                                expression: "objBookingDetailMain.fwdrCstNo",
                              },
                            ],
                            attrs: { name: "fwderSelect", id: "fwderSelect" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.objBookingDetailMain,
                                  "fwdrCstNo",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c("option", { attrs: { value: "" } }, [
                              _vm._v(_vm._s(_vm.$t("msg.CSBK100.050"))),
                            ]),
                            _vm._v(" "),
                            _vm._l(_vm.fwdrList, function (item, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    selected:
                                      _vm.fwdrList.fwdrCstNo ==
                                      _vm.objBookingDetailMain.fwdrCstNo,
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.fwdrCstEnm) + " ")]
                              )
                            }),
                          ],
                          2
                        ),
                        _vm._v("     "),
                        _c("input", {
                          staticClass: "input txt",
                          staticStyle: { width: "220px" },
                          attrs: {
                            type: "text",
                            name: "fwdrCstEnm",
                            id: "fwdrCstEnm",
                            maxlength: "50",
                            readOnly: _vm.cstYn,
                          },
                          domProps: {
                            value: _vm.objBookingDetailMain.fwdrCstEnm,
                          },
                          on: {
                            blur: function ($event) {
                              this.value = this.value.toUpperCase()
                            },
                          },
                        }),
                        _c("input", {
                          staticClass: "input txt",
                          attrs: {
                            type: "hidden",
                            name: "fwdrCstNo",
                            id: "fwdrCstNo",
                          },
                          domProps: {
                            value: _vm.objBookingDetailMain.fwdrCstNo,
                          },
                        }),
                      ]),
                    ])
                  : _vm._e(),
                _c("tr", [
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.267")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c("input", {
                      attrs: { type: "text", disabled: "" },
                      domProps: { value: _vm.objBookingDetailMain.cmdtDsc },
                    }),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.268")))]),
                  _vm._v(" "),
                  _c("td", [
                    _c(
                      "span",
                      { staticClass: "wg" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "splitGrsWtTon",
                            "is-comma": true,
                            "input-class": "wid80",
                            point: 3,
                            maxlength: "30",
                            disabled: "",
                          },
                          model: {
                            value: _vm.grsWtTon,
                            callback: function ($$v) {
                              _vm.grsWtTon = $$v
                            },
                            expression: "grsWtTon",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.269")) + " "),
                      ],
                      1
                    ),
                    _c("span", { staticClass: "color_gray span_grs" }, [
                      _vm._v("/"),
                    ]),
                    _c(
                      "span",
                      { staticClass: "wg" },
                      [
                        _c("e-input-number", {
                          attrs: {
                            id: "splitGrsWtKg",
                            "is-comma": true,
                            "input-class": "wid80",
                            maxlength: "30",
                            disabled: "",
                          },
                          model: {
                            value: _vm.grsWtKg,
                            callback: function ($$v) {
                              _vm.grsWtKg = $$v
                            },
                            expression: "grsWtKg",
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.$t("msg.CSBK100.270")) + " "),
                      ],
                      1
                    ),
                  ]),
                ]),
              ]),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.271"))),
            ]),
            _vm._v(" "),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(1),
              _c("thead", [
                _c(
                  "tr",
                  [
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.274")))]),
                    _vm._v(" "),
                    _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.275")))]),
                    _vm._v(" "),
                    _vm.objBookingDetailMain.polShtmCd == "01"
                      ? [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.276")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.277")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.278")))]),
                        ]
                      : _vm._e(),
                    _vm.objBookingDetailMain.polShtmCd == "02"
                      ? [
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.279")))]),
                          _vm._v(" "),
                          _c("th", { attrs: { colspan: "2" } }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.280"))),
                          ]),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]),
              _c(
                "tbody",
                _vm._l(_vm.objBookingDetailContainer, function (list, i) {
                  return _c("tr", { key: i }, [
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: list.cntrSzCd,
                              expression: "list.cntrSzCd",
                            },
                          ],
                          attrs: {
                            name: "containerSize_" + (i + 1),
                            id: "containerSize_" + (i + 1),
                            disabled: "",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                list,
                                "cntrSzCd",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "20" } }, [
                            _vm._v("20"),
                          ]),
                          _c("option", { attrs: { value: "40" } }, [
                            _vm._v("40"),
                          ]),
                          _c("option", { attrs: { value: "45" } }, [
                            _vm._v("45"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "select",
                        {
                          attrs: {
                            name: "containerType_" + (i + 1),
                            id: "containerType_" + (i + 1),
                            disabled: "",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "0" } }),
                          _vm._l(
                            _vm.cntrTypeList,
                            function (cntrType, subIndex) {
                              return _c(
                                "option",
                                {
                                  key: subIndex,
                                  domProps: {
                                    value: cntrType.cd,
                                    selected: list.cntrTypCd === cntrType.cd,
                                  },
                                },
                                [_vm._v(" " + _vm._s(cntrType.cdNm) + " ")]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _c("td", [_vm._v(_vm._s(list.cntrQty))]),
                    _vm._v(" "),
                    _c("td", [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.objBookingDetailMain.cboTerm,
                              expression: "objBookingDetailMain.cboTerm",
                            },
                          ],
                          attrs: {
                            name: "containerPolTerm_" + (i + 1),
                            id: "containerPolTerm_" + (i + 1),
                            disabled: "",
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.objBookingDetailMain,
                                "cboTerm",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "01" } }, [
                            _vm._v("CY"),
                          ]),
                          _c("option", { attrs: { value: "02" } }, [
                            _vm._v("CFS"),
                          ]),
                        ]
                      ),
                    ]),
                    _c("td", [
                      _c(
                        "div",
                        { staticClass: "input_calendar mid" },
                        [
                          _c("e-date-picker", {
                            attrs: {
                              id: "pickUpDate_" + (i + 1),
                              name: "pickUpDate_" + (i + 1),
                              disabled: true,
                            },
                            model: {
                              value: list.pcupReqDt,
                              callback: function ($$v) {
                                _vm.$set(list, "pcupReqDt", $$v)
                              },
                              expression: "list.pcupReqDt",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm.objBookingDetailMain.polShtmCd == "01"
                      ? _c("td", { attrs: { id: "pickUpTable_" + (i + 1) } }, [
                          _c("p", [
                            _c("input", {
                              attrs: {
                                type: "text",
                                id: "pickUpPlace_" + (i + 1),
                                name: "pickUpPlace_" + (i + 1),
                                disabled: "",
                              },
                              domProps: { value: list.pcupCyNm },
                            }),
                          ]),
                        ])
                      : _vm._e(),
                    _vm.objBookingDetailMain.polShtmCd == "02"
                      ? _c(
                          "td",
                          {
                            attrs: {
                              id: "pickUpTable_" + (i + 1),
                              colspan:
                                _vm.objBookingDetailMain.polShtmCd == "02"
                                  ? 2
                                  : 1,
                            },
                          },
                          [
                            i === 0
                              ? [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.objBookingDetailMain
                                              .cfsFixdTypCd,
                                          expression:
                                            "objBookingDetailMain.cfsFixdTypCd",
                                        },
                                      ],
                                      attrs: {
                                        name: "cfsFixdTypCd_" + (i + 1),
                                        id: "cfsFixdTypCd_" + (i + 1),
                                        disabled: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.objBookingDetailMain,
                                            "cfsFixdTypCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.047"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "02" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.048"))
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("option", { attrs: { value: "03" } }, [
                                        _vm._v(
                                          _vm._s(_vm.$t("msg.CSBK100.283"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.objBookingDetailMain
                                            .cfsFixdEntrNm,
                                        expression:
                                          "objBookingDetailMain.cfsFixdEntrNm",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      name: "cfsFixdEntrNm",
                                      id: "cfsFixdEntrNm",
                                      disabled:
                                        _vm.objBookingDetailMain.cfsFixdTypCd !=
                                        "02",
                                    },
                                    domProps: {
                                      value:
                                        _vm.objBookingDetailMain.cfsFixdEntrNm,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.objBookingDetailMain,
                                          "cfsFixdEntrNm",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              : [_c("p", [_vm._v("-")])],
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.objBookingDetailMain.polShtmCd == "01"
                      ? _c("td", [
                          _c("div", { staticClass: "tbl_form" }, [
                            _c(
                              "span",
                              { staticClass: "dv col_3 font_12 text_left" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.$t("msg.CMBK100_M1.023_1")) +
                                    " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "dv" }, [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "pickUpMan_" + (i + 1),
                                  id: "pickUpMan_" + (i + 1),
                                  disabled: "",
                                },
                                domProps: { value: list.pcupMan },
                              }),
                            ]),
                          ]),
                          _c("div", { staticClass: "tbl_form mt5" }, [
                            _c(
                              "span",
                              { staticClass: "dv col_3 font_12 text_left" },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("msg.CSBK100.285")) + " "
                                ),
                              ]
                            ),
                            _c("span", { staticClass: "dv" }, [
                              _c("input", {
                                attrs: {
                                  type: "text",
                                  name: "pickUpTel_" + (i + 1),
                                  id: "pickUpTel_" + (i + 1),
                                  disabled: "",
                                },
                                domProps: { value: list.pcupTel },
                              }),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                }),
                0
              ),
            ]),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("msg.CSBK100.286"))),
            ]),
            _vm._v(" "),
            _vm.editFlag != "Y"
              ? _vm._l(_vm.objBookingDetailMainList, function (main, index) {
                  return _c(
                    "table",
                    { key: index, staticClass: "tbl_col mt10" },
                    [
                      _vm._m(2, true),
                      _c("tr", [
                        _c("th", { attrs: { rowspan: "2" } }, [
                          index === 0
                            ? _c(
                                "button",
                                {
                                  staticClass: "tbl_icon plus",
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnChangeMain("PLUS", -1)
                                    },
                                  },
                                },
                                [_vm._v("plus")]
                              )
                            : _c(
                                "button",
                                {
                                  class: {
                                    tbl_icon: true,
                                    minus: true,
                                    disabled: main.fixFlag,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnChangeMain("MINUS", index)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                        ]),
                        _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.287")))]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.268"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.258"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }),
                      ]),
                      _c("tr", [
                        _c("td", [_vm._v(_vm._s(index + 1))]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: main.eachGrsWt,
                                expression: "main.eachGrsWt",
                              },
                            ],
                            staticClass: "wid100",
                            attrs: {
                              type: "text",
                              id: "grsWt_" + index,
                              name: "grsWt_" + index,
                            },
                            domProps: { value: main.eachGrsWt },
                            on: {
                              blur: function ($event) {
                                $event.preventDefault()
                                return _vm.chkGrossWeight(main.eachGrsWt, index)
                              },
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(main, "eachGrsWt", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" kg "),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _vm._v(
                            " " + _vm._s(index === 0 ? _vm.bkgNo : "") + " "
                          ),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }),
                      ]),
                      _c(
                        "tr",
                        [
                          _c("th", { attrs: { rowspan: "2" } }, [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon plus",
                                on: {
                                  click: function ($event) {
                                    return _vm.fnChangeSub("PLUS", index, -1)
                                  },
                                },
                              },
                              [_vm._v("plus")]
                            ),
                          ]),
                          _c("th", { staticClass: "border_left" }, [
                            _vm._v(_vm._s(_vm.$t("msg.CSBK100.272"))),
                          ]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.274")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.275")))]),
                          _vm._v(" "),
                          main.polShtmCd === "01"
                            ? [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.276"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.277"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.278"))),
                                ]),
                              ]
                            : _vm._e(),
                          main.polShtmCd === "02"
                            ? [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.279"))),
                                ]),
                                _vm._v(" "),
                                _c("th", { attrs: { colspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.280"))),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._l(
                        _vm.objBookingDetailContainerList,
                        function (cntrList, i) {
                          return [
                            _vm._l(cntrList, function (cntr, k) {
                              return [
                                index == i
                                  ? _c("tr", { key: k }, [
                                      k !== 0
                                        ? _c("td", [
                                            _c(
                                              "button",
                                              {
                                                staticClass: "tbl_icon minus",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.fnChangeSub(
                                                      "MINUS",
                                                      index,
                                                      k
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("minus")]
                                            ),
                                          ])
                                        : _vm._e(),
                                      _c("td", { staticClass: "border_left" }, [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: cntr.newCntrSzCd,
                                                expression: "cntr.newCntrSzCd",
                                              },
                                            ],
                                            attrs: {
                                              name: "cntrSzCd_" + i + "_" + k,
                                              id: "cntrSzCd_" + i + "_" + k,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  cntr,
                                                  "newCntrSzCd",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "20" } },
                                              [_vm._v("20")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "40" } },
                                              [_vm._v("40")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "select",
                                          {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: cntr.newCntrTypCd,
                                                expression: "cntr.newCntrTypCd",
                                              },
                                            ],
                                            attrs: {
                                              name: "cntrTypCd_" + i + "_" + k,
                                              id: "cntrTypCd_" + i + "_" + k,
                                            },
                                            on: {
                                              change: function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  cntr,
                                                  "newCntrTypCd",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              { attrs: { value: "GP" } },
                                              [_vm._v("Dry")]
                                            ),
                                            _c(
                                              "option",
                                              { attrs: { value: "HC" } },
                                              [_vm._v("HIGH CUBE")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: cntr.newCntrQty,
                                              expression: "cntr.newCntrQty",
                                            },
                                          ],
                                          attrs: {
                                            type: "text",
                                            id: "cntrQty_" + i + "_" + k,
                                            name: "cntrQty_" + i + "_" + k,
                                          },
                                          domProps: { value: cntr.newCntrQty },
                                          on: {
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.$set(
                                                cntr,
                                                "newCntrQty",
                                                $event.target.value
                                              )
                                            },
                                          },
                                        }),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "select",
                                          {
                                            attrs: {
                                              name: "cntrTerm_" + i + "_" + k,
                                              id: "cntrTerm_" + i + "_" + k,
                                              disabled: "",
                                            },
                                          },
                                          [
                                            _c(
                                              "option",
                                              {
                                                attrs: { value: "01" },
                                                domProps: {
                                                  selected:
                                                    i == 0 && k == 0
                                                      ? _vm.objBookingDetailMain
                                                          .polShtmCd == "01"
                                                      : cntr.polShtmCd == "01",
                                                },
                                              },
                                              [_vm._v("CY")]
                                            ),
                                            _c(
                                              "option",
                                              {
                                                attrs: { value: "02" },
                                                domProps: {
                                                  selected:
                                                    i == 0 && k == 0
                                                      ? _vm.objBookingDetailMain
                                                          .polShtmCd == "02"
                                                      : cntr.polShtmCd == "02",
                                                },
                                              },
                                              [_vm._v("CFS")]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _c("td", [
                                        _c(
                                          "div",
                                          { staticClass: "input_calendar mid" },
                                          [
                                            _c("e-date-picker", {
                                              attrs: {
                                                id: "pickUpDate_" + i + "_" + k,
                                                name:
                                                  "pickUpDate_" + i + "_" + k,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.fnPickUpDateCheck(
                                                    cntr.newPcupReqDt,
                                                    i,
                                                    k
                                                  )
                                                },
                                              },
                                              model: {
                                                value: cntr.newPcupReqDt,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    cntr,
                                                    "newPcupReqDt",
                                                    $$v
                                                  )
                                                },
                                                expression: "cntr.newPcupReqDt",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm.objBookingDetailMain.polShtmCd == "01"
                                        ? _c(
                                            "td",
                                            {
                                              attrs: { id: "pickUpTable_" + i },
                                            },
                                            [
                                              _c("p", [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: cntr.newPcupCyNm,
                                                      expression:
                                                        "cntr.newPcupCyNm",
                                                    },
                                                  ],
                                                  attrs: {
                                                    type: "text",
                                                    id:
                                                      "pickUpPlace_" +
                                                      i +
                                                      "_" +
                                                      k,
                                                    name:
                                                      "pickUpPlace_" +
                                                      i +
                                                      "_" +
                                                      k,
                                                    readonly: "",
                                                  },
                                                  domProps: {
                                                    value: cntr.newPcupCyNm,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.fnPickUpPlace(
                                                        i,
                                                        k
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        cntr,
                                                        "newPcupCyNm",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.objBookingDetailMain.polShtmCd == "02"
                                        ? _c(
                                            "td",
                                            {
                                              attrs: {
                                                id: "pickUpTable_" + (k + 1),
                                                colspan:
                                                  _vm.objBookingDetailMain
                                                    .polShtmCd == "02"
                                                    ? 2
                                                    : 1,
                                              },
                                            },
                                            [
                                              k === 0
                                                ? [
                                                    _c(
                                                      "select",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              main.newCfsFixdTypCd,
                                                            expression:
                                                              "main.newCfsFixdTypCd",
                                                          },
                                                        ],
                                                        attrs: {
                                                          name:
                                                            "cfsFixdTypCd_" +
                                                            index,
                                                          id:
                                                            "cfsFixdTypCd_" +
                                                            index,
                                                        },
                                                        on: {
                                                          change: [
                                                            function ($event) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                main,
                                                                "newCfsFixdTypCd",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                            function ($event) {
                                                              return _vm.fnChangeShowling(
                                                                main.newCfsFixdTypCd,
                                                                index
                                                              )
                                                            },
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "01",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "msg.CSBK100.047"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "02",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "msg.CSBK100.048"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            attrs: {
                                                              value: "03",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "msg.CSBK100.283"
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            main.newCfsFixdEntrNm,
                                                          expression:
                                                            "main.newCfsFixdEntrNm",
                                                        },
                                                      ],
                                                      attrs: {
                                                        type: "text",
                                                        name:
                                                          "cfsFixdEntrNm_" +
                                                          index,
                                                        id:
                                                          "cfsFixdEntrNm_" +
                                                          index,
                                                        readonly:
                                                          main.newCfsFixdEntrNm !==
                                                          "02",
                                                      },
                                                      domProps: {
                                                        value:
                                                          main.newCfsFixdEntrNm,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          if (
                                                            $event.target
                                                              .composing
                                                          )
                                                            return
                                                          _vm.$set(
                                                            main,
                                                            "newCfsFixdEntrNm",
                                                            $event.target.value
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                : [_c("p", [_vm._v("-")])],
                                            ],
                                            2
                                          )
                                        : _vm._e(),
                                      _vm.objBookingDetailMain.polShtmCd == "01"
                                        ? _c("td", [
                                            _c(
                                              "div",
                                              { staticClass: "tbl_form" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "dv col_3 font_12 text_left",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.CMBK100_M1.023_1"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "dv" },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "text",
                                                        name:
                                                          "pickUpMan_" +
                                                          i +
                                                          "_" +
                                                          k,
                                                        id:
                                                          "pickUpMan_" +
                                                          i +
                                                          "_" +
                                                          k,
                                                        disabled: "",
                                                      },
                                                      domProps: {
                                                        value: cntr.pcupMan,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "tbl_form mt5" },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "dv col_3 font_12 text_left",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.CSBK100.285"
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "span",
                                                  { staticClass: "dv" },
                                                  [
                                                    _c("input", {
                                                      attrs: {
                                                        type: "text",
                                                        name:
                                                          "pickUpTel_" +
                                                          i +
                                                          "_" +
                                                          k,
                                                        id:
                                                          "pickUpTel_" +
                                                          i +
                                                          "_" +
                                                          k,
                                                        disabled: "",
                                                      },
                                                      domProps: {
                                                        value: cntr.pcupTel,
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ])
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        }
                      ),
                    ],
                    2
                  )
                })
              : _vm._l(_vm.splitDetailList, function (main, index) {
                  return _c(
                    "table",
                    { key: index, staticClass: "tbl_col mt10" },
                    [
                      _vm._m(3, true),
                      _c("tr", [
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.287"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.268"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(_vm.$t("msg.CSBK100.258"))),
                        ]),
                        _vm._v(" "),
                        _c("th", { attrs: { colspan: "2" } }),
                      ]),
                      _c("tr", [
                        _c("td", { attrs: { colspan: "2" } }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: main.grsWt,
                                expression: "main.grsWt",
                              },
                            ],
                            staticClass: "wid100",
                            attrs: {
                              type: "text",
                              id: "grsWt_" + index,
                              name: "grsWt_" + index,
                              disabled: "",
                            },
                            domProps: { value: main.grsWt },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(main, "grsWt", $event.target.value)
                              },
                            },
                          }),
                          _vm._v(" kg "),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }, [
                          index === 0
                            ? _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnDetail(_vm.bkgNo)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(_vm.bkgNo))]
                              )
                            : _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.fnDetail(main.nwBkgNo)
                                    },
                                  },
                                },
                                [_vm._v(_vm._s(main.nwBkgNo))]
                              ),
                        ]),
                        _c("td", { attrs: { colspan: "2" } }),
                      ]),
                      _c(
                        "tr",
                        [
                          _c(
                            "th",
                            {
                              staticClass: "border_left",
                              attrs: { colspan: "2" },
                            },
                            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.272")))]
                          ),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.273")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.274")))]),
                          _vm._v(" "),
                          _c("th", [_vm._v(_vm._s(_vm.$t("msg.CSBK100.275")))]),
                          _vm._v(" "),
                          _vm.objBookingDetailMain.polShtmCd === "01"
                            ? [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.276"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.277"))),
                                ]),
                                _vm._v(" "),
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.278"))),
                                ]),
                              ]
                            : _vm._e(),
                          _vm.objBookingDetailMain.polShtmCd === "02"
                            ? [
                                _c("th", [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.279"))),
                                ]),
                                _vm._v(" "),
                                _c("th", { attrs: { colspan: "2" } }, [
                                  _vm._v(_vm._s(_vm.$t("msg.CSBK100.280"))),
                                ]),
                              ]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._l(_vm.splitCntrList, function (cntr, i) {
                        return [
                          main.bkgPrtlDtlSeq == cntr.bkgPrtlDtlSeq
                            ? _c("tr", { key: i }, [
                                _c(
                                  "td",
                                  {
                                    staticClass: "border_left",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: cntr.cntrSzCd,
                                            expression: "cntr.cntrSzCd",
                                          },
                                        ],
                                        attrs: {
                                          name: "cntrSzCd_" + index + "_" + i,
                                          id: "cntrSzCd_" + index + "_" + i,
                                          disabled: "",
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              cntr,
                                              "cntrSzCd",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { attrs: { value: "20" } },
                                          [_vm._v("20")]
                                        ),
                                        _c(
                                          "option",
                                          { attrs: { value: "40" } },
                                          [_vm._v("40")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: cntr.cntrTypCd,
                                          expression: "cntr.cntrTypCd",
                                        },
                                      ],
                                      attrs: {
                                        name: "cntrTypCd_" + index + "_" + i,
                                        id: "cntrTypCd_" + index + "_" + i,
                                        disabled: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            cntr,
                                            "cntrTypCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "GP" } }, [
                                        _vm._v("Dry"),
                                      ]),
                                      _c("option", { attrs: { value: "HC" } }, [
                                        _vm._v("HIGH CUBE"),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: cntr.cntrQty,
                                        expression: "cntr.cntrQty",
                                      },
                                    ],
                                    attrs: {
                                      type: "text",
                                      id: "cntrQty_" + index + "_" + i,
                                      name: "cntrQty_" + index + "_" + i,
                                      disabled: "",
                                    },
                                    domProps: { value: cntr.cntrQty },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          cntr,
                                          "cntrQty",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _c("td", [
                                  _c(
                                    "select",
                                    {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.objBookingDetailMain.polShtmCd,
                                          expression:
                                            "objBookingDetailMain.polShtmCd",
                                        },
                                      ],
                                      attrs: {
                                        name: "cntrTerm_" + index + "_" + i,
                                        id: "cntrTerm_" + index + "_" + i,
                                        disabled: "",
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.objBookingDetailMain,
                                            "polShtmCd",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c("option", { attrs: { value: "01" } }, [
                                        _vm._v("CY"),
                                      ]),
                                      _c("option", { attrs: { value: "02" } }, [
                                        _vm._v("CFS"),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _c("td", [
                                  _c(
                                    "div",
                                    { staticClass: "input_calendar mid" },
                                    [
                                      _c("e-date-picker", {
                                        attrs: {
                                          id: "pickUpDate_" + index + "_" + i,
                                          name: "pickUpDate_" + index + "_" + i,
                                          disabled: "",
                                        },
                                        model: {
                                          value: cntr.pcupReqDt,
                                          callback: function ($$v) {
                                            _vm.$set(cntr, "pcupReqDt", $$v)
                                          },
                                          expression: "cntr.pcupReqDt",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm.objBookingDetailMain.polShtmCd == "01"
                                  ? _c(
                                      "td",
                                      { attrs: { id: "pickUpTable_" + i } },
                                      [
                                        _c("p", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: cntr.pcupCyNm,
                                                expression: "cntr.pcupCyNm",
                                              },
                                            ],
                                            attrs: {
                                              type: "text",
                                              id:
                                                "pickUpPlace_" +
                                                index +
                                                "_" +
                                                i,
                                              name:
                                                "pickUpPlace_" +
                                                index +
                                                "_" +
                                                i,
                                              readonly: "",
                                            },
                                            domProps: { value: cntr.pcupCyNm },
                                            on: {
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  cntr,
                                                  "pcupCyNm",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm.objBookingDetailMain.polShtmCd == "02"
                                  ? _c(
                                      "td",
                                      {
                                        attrs: {
                                          id: "pickUpTable_" + (i + 1),
                                          colspan:
                                            _vm.objBookingDetailMain
                                              .polShtmCd == "02"
                                              ? 2
                                              : 1,
                                        },
                                      },
                                      [
                                        i === 0
                                          ? [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: main.cfsFixdTypCd,
                                                      expression:
                                                        "main.cfsFixdTypCd",
                                                    },
                                                  ],
                                                  attrs: {
                                                    name:
                                                      "cfsFixdTypCd_" + index,
                                                    id: "cfsFixdTypCd_" + index,
                                                    disabled: "",
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          main,
                                                          "cfsFixdTypCd",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                      function ($event) {
                                                        return _vm.fnChangeShowling(
                                                          main.cfsFixdTypCd,
                                                          index
                                                        )
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    { attrs: { value: "01" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.CSBK100.047"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    { attrs: { value: "02" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.CSBK100.048"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    { attrs: { value: "03" } },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "msg.CSBK100.283"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: main.cfsFixdEntrNm,
                                                    expression:
                                                      "main.cfsFixdEntrNm",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "text",
                                                  name:
                                                    "cfsFixdEntrNm_" + index,
                                                  id: "cfsFixdEntrNm_" + index,
                                                  readonly:
                                                    main.cfsFixdEntrNm !== "02",
                                                },
                                                domProps: {
                                                  value: main.cfsFixdEntrNm,
                                                },
                                                on: {
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      main,
                                                      "cfsFixdEntrNm",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : [_c("p", [_vm._v("-")])],
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                _vm.objBookingDetailMain.polShtmCd == "01"
                                  ? _c("td", [
                                      _c("div", { staticClass: "tbl_form" }, [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "dv col_3 font_12 text_left",
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t("msg.CMBK100_M1.023_1")
                                                ) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _c("span", { staticClass: "dv" }, [
                                          _c("input", {
                                            attrs: {
                                              type: "text",
                                              name:
                                                "pickUpMan_" + index + "_" + i,
                                              id:
                                                "pickUpMan_" + index + "_" + i,
                                              disabled: "",
                                            },
                                            domProps: { value: cntr.pcupMan },
                                          }),
                                        ]),
                                      ]),
                                      _c(
                                        "div",
                                        { staticClass: "tbl_form mt5" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dv col_3 font_12 text_left",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t("msg.CSBK100.285")
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                          _c("span", { staticClass: "dv" }, [
                                            _c("input", {
                                              attrs: {
                                                type: "text",
                                                name:
                                                  "pickUpTel_" +
                                                  index +
                                                  "_" +
                                                  i,
                                                id:
                                                  "pickUpTel_" +
                                                  +index +
                                                  "_" +
                                                  i,
                                                disabled: "",
                                              },
                                              domProps: { value: cntr.pcupTel },
                                            }),
                                          ]),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ]
                      }),
                    ],
                    2
                  )
                }),
          ],
          2
        ),
        _c("div", { staticClass: "mt10 text_center" }, [
          _vm.editFlag != "Y"
            ? _c(
                "a",
                {
                  staticClass: "button blue lg",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.fnSplit($event)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("msg.CSBK100.231")))]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button blue lg",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.fnDetail(_vm.bkgNo)
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.CSBK100.320")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg float_right",
              on: {
                click: function ($event) {
                  return _vm.closePopup("Y")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX020P130.068")))]
          ),
        ]),
      ]),
      _c(
        "win-layer-pop",
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "18%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "24%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "24%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticStyle: { width: "6%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "12%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "10%" } }),
      _c("col", { staticStyle: { width: "8%" } }),
      _c("col", { staticStyle: { width: "20%" } }),
      _c("col", { staticStyle: { width: "24%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }