import Send from '../../trans.client.js'

export default {
  //Booking Split 신규 화면
  getBookingSplitDetail (formData) {
    return Send({
      url: '/trans/booking-split/' + formData.bkgNo + '/' + formData.editFlag,
      method: 'get'
    })
  },
  //Insert Booking split
  startBookingSplit (formData) {
    return Send({
      url: '/trans/booking-split/',
      method: 'post',
      data: formData
    })
  }
}
